*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 18px;
    line-height: 1.4;
    -webkit-tap-highlight-color: transparent;
    min-height: 100%;
    height: 100%;
}

body {
    background: #fff;
    color: $color-dark-light;
    font-family: $base-font-family;
    font-size: inherit;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    min-height: 100%;
    height: 100%;
}

a, .link {
    color: $color-accent;
    outline: none;
    text-decoration: none;
    @extend %animation;
}
a:focus,
a:hover,
a:active,
.link:focus,
.link:hover,
.link:active {
    color: $color-accent-dark;
    text-decoration: underline;
}

a:visited {
    color: $color-accent;
}
.page {
    display: flex;
    flex-flow: column;
    min-height: 100%;
}
.main {
    flex: 1 0 auto;
}
.footer {
    flex: 0 0 auto;
}
.main--not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0,0,0,.1);
}
.section-title--center {
    text-align: center;
    margin: auto;
}
