@import 'base/variables';
@import 'base/mixins';
@import 'base/fonts';
@import 'base/normalize';
@import 'base/default';
@import 'parts/typography';
@import "base/forms";
@import "base/utilities";
img {
    display: block;
}
.loading {
    width: 24px;
    height: 24px;
    margin: auto;
    animation: sk-chase 2.5s infinite linear both;
}
.loading--absolute {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.loading__dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: preloader__dot 2.0s infinite ease-in-out both;
}
.loading__dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #1d1d1d;
    border-radius: 100%;
    animation: preloader__dot-before 2.0s infinite ease-in-out both;
}
.loading__dot:nth-child(1) {
    animation-delay: -1.1s;
}
.loading__dot:nth-child(2) {
    animation-delay: -1.0s;
}
.loading__dot:nth-child(3) {
    animation-delay: -0.9s;
}
.loading__dot:nth-child(4) {
    animation-delay: -0.8s;
}
.loading__dot:nth-child(5) {
    animation-delay: -0.7s;
}
.loading__dot:nth-child(6) {
    animation-delay: -0.6s;
}
.loading__dot:nth-child(1):before {
    animation-delay: -1.1s;
}
.loading__dot:nth-child(2):before {
    animation-delay: -1.0s;
}
.loading__dot:nth-child(3):before {
    animation-delay: -0.9s;
}
.loading__dot:nth-child(4):before {
    animation-delay: -0.8s;
}
.loading__dot:nth-child(5):before {
    animation-delay: -0.7s;
}
.loading__dot:nth-child(6):before {
    animation-delay: -0.6s;
}
@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes preloader__dot {
    80%, 100% {
        transform: rotate(360deg);
    }
}
@keyframes preloader__dot-before {
    50% {
        transform: scale(0.4);
    }
    100%, 0% {
        transform: scale(1.0);
    }
}
.text-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.text-white {
    color: #fff;
}
.loaded + .loading {
    display: none;
}
.swiper-container-initialized .loading {
    display: none;
}
.page {
    @extend %page;
    background: $bg-gray-light;
}
.container {
    @extend %container;
}
.header__container {
    @extend %container;
    padding-top: 40px;
    padding-bottom: 40px;
}
.header__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    display: block;
}
.logo__img {
    height: 40px;
    width: auto;
    margin-bottom: 15px;
}
.nav-horizontal {
    display: flex;
    align-items: center;
}
.nav-horizontal__item {
    font-size: 17px;
    line-height: 1.2;
    margin: 0;
    list-style: none;
    color: $color-dark-light;
    cursor: pointer;
}
.nav-horizontal__item + .nav-horizontal__item {
    margin-left: 25px;
}
.header__right {
    display: flex;
    align-items: center;
}
.phone {
    font-weight: bold;
    color: $color-dark;
}
.phone--ml {
    margin-left: 20px;
}
.footer .nav-horizontal__item {
    color: #fff;
}
.hero {
    @extend %container;
    padding-top: 120px;
    padding-bottom: 180px;
    height: 700px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.hero__row {
    display: block;
    position: relative;
}
.hero__picture {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.hero__title {
    max-width: 900px;
}
.hero__subtitle {
    font-size: 20px;
    line-height: 25px;
    margin-top: 25px;
    max-width: 505px;
}
.slider {
    position: relative;
}
.slider__container {
    @extend %container;
}
.slider__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider__image {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.slider__control {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 25px;
}
.slider .swiper-control__fraction {
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}
.slider__bg {
    position: absolute;
    z-index: 0;
    background-color: $bg-gray;
    left: 0;
    right: 0;
    top: 140px;
    bottom: 0;
}
.china-market {
    background-color: $bg-gray;
    overflow: hidden;
    position: relative;
}
.china-market__container {
    @extend %container;
    @extend %pt-140;
    @extend %pb-100;
}
.section-title {
    margin-bottom: 55px;
    max-width: 900px;
}
.section-subtitle {
    @extend %text;
    max-width: 900px;
    margin-bottom: 75px;
}
.line {
    width: 60px;
    height: 2px;
    background: $color-accent;
}
.oval {
    width: 900px;
    height: 900px;
    background: $color-accent;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    right: -450px;
}
.oval--top {
    top: -450px;
}
.oval--bottom {
    bottom: -450px;
}
.section-subtitle--center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.china-market__text {
    font-size: 24px;
    line-height: 34px;
    max-width: 900px;
    margin-bottom: 100px;
}
.benefits {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    position: relative;
    z-index: 2;
}
.benefits__item {
    flex-basis: 340px;
}
.benefits__item--margin-bottom {
    margin-bottom: 75px;
}
.benefits__item:nth-child(2n-1) {
    margin-right: 170px;
}
.benefits__number {
    margin-bottom: 30px;
}
.benefits__text {
    @extend %text;
    margin-top: 20px;
    opacity: 0.8;
}
.china-web {
    @extend %container;
    @extend %pt-100;
    @extend %pb-140;
}
.web-items {
    display: flex;
}
.web-items__left {
    flex-basis: 270px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 20px 0;
}
.web-items__left .web-items__card::after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 300px;
    height: 1px;
    background: #424a50;
    right: -300px;
    opacity: .5;
}
.web-items__center {
    flex: 1 1 auto;
    position: relative;
    z-index: 2;
}
.web-items__image {
    width: 650px;
    margin: auto;
    border-radius: 50%;
}
.web-items__right {
    flex-basis: 270px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 20px 0;
}
.web-items__right .web-items__card::after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 300px;
    height: 1px;
    background: #424a50;
    left: -300px;
    opacity: .5;
}
.web-items__card {
    @extend %text;
    background: $bg-gray;
    color: $color-dark;
    padding: 20px;
    position: relative;
    border-bottom: 2px solid $color-accent;
}
.variants {
    background-color: $bg-gray;
    @extend %container;
    @extend %pt-100;
    @extend %pb-140;
}
.variants-items {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
}
.variants-item {
    padding: 30px;
    margin: 25px;
    width: calc(50% - 50px);
    flex: 0 1 calc(50% - 50px);
    background: $bg-gray-light;
}
.variants-item__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.variants-item__icon {
    margin-right: 16px;
    @extend %icon;
}
//.variants-title {
//    background-color: $bg-gray;
//    position: relative;
//}
//.variants-title__container {
//    @extend %container;
//    @extend %pt-100;
//    padding-bottom: 0;
//}
//.variants-title__wrap {
//    display: flex;
//    align-items: center;
//    padding-bottom: 75px;
//    flex-wrap: wrap;
//}
//.variants-title__title {
//    width: 50%;
//    flex: 0 0 50%;
//    padding-right: 60px;
//    margin-bottom: 0 !important;
//}
//.variants-control {
//    width: 50%;
//    flex: 0 0 50%;
//    padding-left: 60px;
//    justify-content: end;
//}
//.prices {
//    background-color: $bg-gray;
//    position: relative;
//}
//.prices__container {
//    @extend %container;
//    padding-top: 0;
//    @extend %pb-100;
//    position: relative;
//}
//.prices-slider__slide {
//    padding: 60px 40px;
//    background: #fff;
//    //height: 100%;
//    display: flex;
//    flex-flow: column;
//}
//.prices-slider__title {
//    margin-bottom: 40px;
//}
//.prices-slider__button {
//    margin-top: auto;
//}
//.prices-slider__button .button {
//    margin-top: 40px;
//}
//.prices-slider__text {
//    margin-bottom: 24px;
//}
//.prices-slider__price {
//    margin-top: 4px;
//}
//.prices-slider__small {
//    font-size: 16px;
//}
//.prices-slider .swiper-slide {
//    height: auto;
//}
//.variants {
//    background-color: $bg-gray;
//    position: relative;
//}
//.variants__panel-container {
//    @extend %container;
//    padding-top: 0;
//    @extend %pb-100;
//    position: relative;
//}
//.variants-panel {
//    width: 50%;
//    padding-right: 60px;
//}
//.variants-panel .swiper-wrapper {
//    min-height: 532px;
//}
//.variants-panel__title {
//    font-weight: bold;
//    display: flex;
//    align-items: center;
//    margin-bottom: 16px;
//}
//.variants-panel__icon {
//    margin-left: 20px;
//    display: block;
//    width: 50px;
//    height: 50px;
//}
//.variants-panel__text + .variants-panel__text {
//    margin-top: 24px;
//}
//.variants-slider {
//    position: absolute;
//    z-index: 1;
//    top: 0;
//    bottom: 0;
//    right: 0;
//    left: 50%;
//}
//.variants-slider__content {
//    display: none;
//}
//.variants-slider__image {
//    margin: 56px auto 0;
//    max-width: 100%;
//}
.services {
    @extend %container;
    @extend %pt-100;
    @extend %pb-140;
}
.services-items {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
}
.services-items__item {
    padding: 30px;
    background: $bg-gray;
    margin: 25px;
    width: calc(33.3333% - 50px);
    flex: 0 1 calc(33.3333% - 50px);
}
.services-items__icon {
    @extend %icon;
    margin-bottom: 30px;
}
.services-items__line {
    width: 50px;
    height: 2px;
    background: $color-accent;
}
.services-items__title {
    font-size: 25px;
    margin-bottom: 12px;
    margin-top: 20px;
    color: $color-dark;
}
.services-items__text {
    @extend %text;
    color: $color-dark-light;
    line-height: 1.5;
}
.main-form {
    background: $bg-gray;
    overflow: hidden;
    position: relative;
}
.main-form__container {
    @extend %container;
    @extend %pt-100;
    @extend %pb-140;
}
.about-us {
    position: relative;
}
.about-us__container {
    @extend %container;
    @extend %pt-100;
    padding-bottom: 0;
}
.about-row {
    display: flex;
    margin-bottom: 75px;
}
.about-row__left {
    width: 50%;
    flex: 0 0 50%;
    padding-right: 60px;
}
.about-row__right {
    width: 50%;
    flex: 0 0 50%;
    padding-left: 60px;
}
.about-line {
    display: flex;
    align-items: center;
}
.about-line__icon {
    @extend %icon;
    margin-right: 30px;
}
.about-line__text {
    @extend %text;
    max-width: 355px;
}
.about-line + .about-line {
    margin-top: 50px;
}
.about-us__image {
    margin: 0 auto 100px;
    max-width: 100%;
}
.map {
    position: relative;
}
.map__container {
    @extend %container;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 1;
}
.map__row {
    display: flex;
    height: 550px;
}
.map__map {
    flex: 1 1 auto;
}
.map__panel {
    max-width: 350px;
    flex-basis: 350px;
    padding: 50px;
    background: $bg-gray;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}
.map__logo-img {
    height: 40px;
    width: auto;
}
.map__text {
    @extend %text;
    margin-bottom: 20px;
}
.map__contact {
    @extend %text;
    color: $color-dark;
    display: block;
}
.map__contact + .map__contact {
    margin-top: 4px;
}
.map__bg {
    background: $color-dark;
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 140px;
    bottom: 0;
}
.footer {
    background: $color-dark;
}
.footer__container {
    @extend %container;
    padding-top: 50px;
    padding-bottom: 50px;
}
.footer__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer__text {
    @extend %text-2;
    color: #fff;
}
.container h4 {
    margin-bottom: 20px;
}
.container h5 {
    margin-bottom: 16px;
}
.container .descr {
    margin-bottom: 16px;
}
.container .ol {
    margin-bottom: 16px;
}
.container .li {
    margin-bottom: 16px;
}
div#inputResult {
    padding-bottom: 50px;
}
@import "vendors/vendors";
@import "parts/modals";
@import "parts/cookie";
@import "base/responsive";
