h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    color: $color-dark;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: 0;
    font-family: $title-font-family;
}
h1,
.h1 {
    font-size: 72px;
}
h2,
.h2 {
    font-size: 48px;
}
h3,
.h3 {
    font-size: 28px;
}
h4,
.h4 {
    font-size: 25px;
}
h5,
.h5 {
    font-size: 20px;
}
h6,
.h6 {
    font-size: 15px;
}

p {
    margin: 0 0 25px;
}
p:last-child {
    margin-bottom: 0;
}

ul,
ol {
    margin: 16px 0 16px 24px;
    padding: 0;
}
ul ul,
ol ol {
    margin: 16px 0 16px 24px;
}

li {
    margin-top: 4px;
}
