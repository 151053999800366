%page {
    max-width: 1600px;
    margin: 0 auto;
    background: #fff;
}
%container-full {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
}
%container-padding {
    padding-left: 100px;
    padding-right: 100px;
}
%container {
    @extend %container-full;
    @extend %container-padding;
}
%pt-150 {
    padding-top: 150px;
}
%pt-140 {
    padding-top: 140px;
}
%pt-100 {
    padding-top: 100px;
}
%pt-75 {
    padding-top: 75px;
}
%pb-100 {
    padding-bottom: 100px;
}
%pb-140 {
    padding-bottom: 140px;
}
%pb-75 {
    padding-bottom: 75px;
}
%pt-50 {
    padding-top: 50px;
}
%pb-50 {
    padding-bottom: 50px;
}
%row-margins {
    margin: 0 -20px;
}
%col-paddings {
    padding: 20px;
}
%icon-oval {
    width: 106px;
    height: 106px;
    flex: 0 0 106px;
    font-size: 48px;
    font-weight: 400;
}
%text {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $base-font-family;
}
%text-2 {
    font-size: 0.89rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $base-font-family;
}
%text-3 {
    font-size: 0.78rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $base-font-family;
}
%text-4 {
    font-size: 0.67rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $base-font-family;
}

%animation {
    transition: all .3s cubic-bezier(.25,.46,.45,.94);
}
%shadow {
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1), 15px 15px 15px rgba(0, 0, 0, 0.1);
}
%icon {
    width: 50px;
    height: 50px;
    display: block;
    background: transparent;
    flex: 0 0 50px;
    fill: $color-dark;
    @extend %animation;
}
