.swiper-control {
    display: flex;
    align-items: center;
}
.swiper-control__fraction {
    display: flex;
    align-items: flex-end;
    font-size: 36px;
    color: #1d1d1d;
    line-height: 1.2;
}
.swiper-control__fraction-current {
    color: #1d1d1d;
    padding-right: 2px;
    display: block;
}
.swiper-control__fraction-total {
    color: #1d1d1d;
    opacity: .6;
    padding-left: 2px;
    display: block;
}
.swiper-control__prev {
    margin-right: 40px;
    cursor: pointer;
}
.swiper-control__prev svg {
    display: block;
}
.swiper-control__next {
    margin-left: 40px;
    cursor: pointer;
}
.swiper-control__next svg {
    display: block;
}
.swiper-control__prev.is-disabled {
    cursor: default;
    border: 0;
}
.swiper-control__next.is-disabled {
    cursor: default;
    border: 0;
}
.swiper-control__prev.is-disabled svg {
    opacity: .3;
}
.swiper-control__next.is-disabled svg {
    opacity: .3;
}
.swiper-pagination-fraction {
    width: auto;
    bottom: initial;
    left: initial;
    display: flex;
    align-items: flex-end;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.swiper-control .is-lock {
    display: none;
}
.swiper-container.is-hidden {
    display: none;
}
.swiper-wrapper {
    opacity: 0;
    @extend %animation;
}
.swiper-container-initialized .swiper-wrapper {
    opacity: 1;
}
