.is-full-width {
    width: 100%;
}
.bg {
    position: absolute;
    z-index: 2;
    background: rgba(0,0,0,.3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    @extend %animation;
}
.swiper-slide:hover .bg {
    opacity: 1;
}
.gallery__item .bg {
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
}
.gallery__item:hover .bg {
    opacity: 1;
}
