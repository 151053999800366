.cookie-notice {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
    box-shadow: 0 -1px 0 0 rgba(0,0,0, .1);
    z-index: 850;
    padding: 25px 0;
    @extend %animation;
    opacity: 0;
    visibility: hidden;
}
.cookie-notice.is-visible {
    opacity: 1;
    visibility: visible;
}
.cookie-notice__container {
    @extend %container;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}
.cookie-notice__text {
    @extend %text-3;
    color: #fff;
    margin-right: 20px;
}
.cookie-notice__button {
    height: 30px;
    cursor: pointer;
    width: auto;
    font-size: 14px;
}
