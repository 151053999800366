label, .label {
    @extend %text-3;
    line-height: 14px;
    color: $color-dark-light;
    max-width: 100%;
    margin: 0 0 10px;
    padding: 0;
    display: block;
}
.modal label, .modal .label {
    color: $color-dark;
}

input, .input {
    border: 1px solid $color-dark-light;
    padding: 0 15px;
    @extend %text-2;
    height: 48px;
    width: auto;
    max-width: 100%;
    background: transparent;
    color: $color-dark;
    display: block;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
    @extend %animation;
}
input::placeholder, .input::placeholder {
    @extend %animation;
    color: $color-dark-light;
    opacity: .3;
}
input:focus::placeholder, .input:focus::placeholder {
    opacity: .4;
}
input:hover, .input:hover {
    border: 1px solid $color-dark;
}
input:focus, .input:focus {
    border: 1px solid $color-dark;
}

textarea, .textarea {
    border: 1px solid $color-dark-light;
    padding: 15px;
    @extend %text-2;
    width: auto;
    max-width: 100%;
    background: transparent;
    color: $color-dark;
    display: block;
    outline: none;
    resize: none;
    height: 145px;
    -webkit-appearance: none;
    border-radius: 0;
    @extend %animation;
}
textarea::placeholder, .textarea::placeholder {
    @extend %animation;
    color: $color-dark-light;
    opacity: .3;
}
textarea:focus::placeholder, .textarea:focus::placeholder {
    opacity: .4;
}
textarea:hover, .textarea:hover {
    border: 1px solid $color-dark;
}
textarea:focus, .textarea:focus {
    border: 1px solid $color-dark;
}

button, .button {
    padding: 0 15px;
    @extend %text-3;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .5px;
    height: 50px;
    width: auto;
    max-width: 100%;
    text-transform: uppercase;
    background: $color-accent;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    -webkit-appearance: none;
    @extend %animation;
}
button:hover, .button:hover {
    background: $color-accent-dark;
}
button:active, .button:active {
    background: $color-accent-dark;
}
.button__icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    display: block;
    margin-right: 8px;
}

.button-rounded {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $color-accent;
    border: 0;
    user-select: none;
    outline: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
    @extend %animation;
}
.button-rounded__icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    position: relative;
    z-index: 2;
}
.button-rounded--center {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.button-rounded--zoom {
    opacity: 0;
}
.swiper-slide:hover .button-rounded--zoom {
    opacity: 1;
}
.gallery__item:hover .button-rounded--zoom {
    opacity: 1;
}

.founders-form {
    max-width: 460px;
    margin: 0 auto;
}
.experts-form {
    max-width: 460px;
    margin: 0 auto;
}
.form-group {
    margin-bottom: 25px;
    position: relative;
}
.policy {
    @extend %text-2;
    color: $color-dark-light;
    margin-top: 15px;
}
.policy__line {
    margin-bottom: 20px;
}

.footer__form {
    padding: 75px 0;
}
.form__wrap {
    max-width: 900px;
}
.form__row {
    display: flex;
    margin: -25px;
    justify-content: center;
    flex-wrap: wrap;
}
.form__column {
    padding: 25px;
    width: 50%;
}
.form__textarea {
    margin-bottom: 49px;
}
.h3.form__title-text {
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
}
.form__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
}
.form__title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    margin-right: 20px;
}
.form__title-svg {
    display: block;
    width: 32px;
    height: 32px;
}
.form-field-error {
    @extend %text-4;
    color: red;
    margin-top: 2px;
    display: none;
    text-align: right;
    position: absolute;
    bottom: -16px;
    right: 0;
}
.form-field-error.visible {
    display: block;
}
.form-hidden {
    display: none;
}
.form-group.has-error input {
    border-color: red;
}
.form-group.has-error select {
    border-color: red;
}
.form-group.has-error textarea {
    border-color: red;
}
.form__content.is-hidden {
    opacity: .3;
}
.form__success-title {
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
}
.form__success-message {
    text-align: center;
    color: #fff;
    opacity: 0.6;
}
.form__success-reload {
    text-align: center;
    color: #fff;
    opacity: 0.3;
    margin-bottom: 0;
}
.form {
    position: relative;
    @extend %animation;
}
.form__success {
    max-width: 100%;
    padding: 30px;
    background: $color-dark;
    position: absolute;
    width: 900px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    @extend %animation;
}
.form__success.is-visible {
    opacity: 1;
    visibility: visible;
}
