.modal {
    position: fixed;
    z-index: 1002;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    display: flex;
    background: rgba(0, 0, 0, 0.55);
}
.modal.is-show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: opacity .6s ease, visibility .6s ease;
}
.modal__wrapper {
    margin: auto;
}
.modal__container {
    width: 100%;
    min-width: 480px;
    max-width: 900px;
    padding: 50px;
    background: #fff;
    @extend %shadow;
}
.modal__container--video {
    max-width: 1280px;
}
.modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.h3.modal__title {
    margin-bottom: 0;
    margin-right: 50px;
    text-transform: uppercase;
    font-weight: 400;
}
.modal__close {
    cursor: pointer;
}
.modal__close-icon {
    @extend %icon;
    fill: $color-dark-light;
}
.modal__close:hover .modal__close-icon {
    fill: red;
}
.modal__video {
    max-width: 100%;
}
body.is-modal {
    overflow: hidden;
    height: 100%;
}
.disable-mouse {
    pointer-events: none;
}
.modal__policy {
    @extend %text-2;
    color: $color-dark-light;
    margin-top: 24px;
}
