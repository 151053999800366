@media (max-width: 1600px) {
    %container-padding {
        padding-left: 75px;
        padding-right: 75px;
    }
    .header__container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .oval {
        width: 800px;
        height: 800px;
        right: -400px;
    }
    .oval--top {
        top: -400px;
    }
    .oval--bottom {
        bottom: -400px;
    }
    .swiper-control svg {
        display: block;
        width: 48px;
        height: auto;
    }
}
@media (max-width: 1440px) {
    html {
        font-size: 18px;
    }
    h1,
    .h1 {
        font-size: 49.7px;
    }
    h2,
    .h2 {
        font-size: 34.7px;
    }
    h3,
    .h3 {
        font-size: 25.9px;
    }
    h4,
    .h4 {
        font-size: 18px;
    }
    .oval {
        width: 700px;
        height: 700px;
        right: -350px;
    }
    .oval--top {
        top: -350px;
    }
    .oval--bottom {
        bottom: -350px;
    }
    .web-items__image {
        width: 600px;
    }
}
@media (max-width: 1280px) {
    .nav-horizontal__item {
        font-size: 15px;
    }
    %container-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
    html {
        font-size: 16px;
    }
    h1,
    .h1 {
        font-size: 39.8px;
    }
    h2,
    .h2 {
        font-size: 27.8px;
    }
    h3,
    .h3 {
        font-size: 20.7px;
    }
    h4,
    .h4 {
        font-size: 16px;
    }
    .section-title {
        margin-bottom: 50px;
    }
    .section-subtitle {
        margin-bottom: 35px;
    }
    %row-margins {
        margin: 0 -10px;
    }
    %col-paddings {
        padding: 10px;
    }
    .logo__img {
        height: 36px;
    }
    .china-market__text {
        font-size: 20px;
        line-height: 28px;
    }
    .benefits {
        max-width: 800px;
    }
    .benefits__item {
        flex-basis: 270px;
    }
    .oval {
        width: 600px;
        height: 600px;
        right: -300px;
    }
    .oval--top {
        top: -300px;
    }
    .oval--bottom {
        bottom: -300px;
    }
    .hero {
        padding-top: 100px;
        padding-bottom: 75px;
    }
    .slider__bg {
        top: 80px;
    }
    .map__bg {
        top: 80px;
    }
    %pt-140 {
        padding-top: 70px;
    }
    %pb-100 {
        padding-bottom: 50px;
    }
    %pt-100 {
        padding-top: 50px;
    }
    %pb-140 {
        padding-bottom: 70px;
    }
    .about-row {
        margin-bottom: 50px;
    }
    .about-us__image {
        margin-bottom: 50px;
    }
    .section-title {
        margin-bottom: 35px;
    }
    .china-market__text {
        margin-bottom: 50px;
    }
    .benefits__item:nth-child(2n-1) {
        margin-right: 110px;
    }
    .benefits__item--margin-bottom {
        margin-bottom: 50px;
    }
    .web-items__image {
        width: 500px;
    }
    .variants-title__wrap {
        padding-bottom: 20px;
    }
    .services-items {
        margin: -20px;
    }
    .services-items__item {
        padding: 25px;
        margin: 20px;
        width: calc(33.3333% - 40px);
        flex: 0 0 calc(33.3333% - 40px);
    }
    .variants-items {
        margin: -20px;
    }
    .variants-item {
        padding: 25px;
        margin: 20px;
        width: calc(50% - 40px);
        flex: 0 0 calc(50% - 40px);
    }
    .map__row {
        height: 450px;
    }
    .hero {
        height: 600px;
        padding-top: 0;
    }
    .hero__picture {
        width: 480px;
    }
}
@media (max-width: 1024px) {
    .logo__img {
        height: 30px;
    }
    .nav-horizontal__item {
        font-size: 15px;
    }
    .nav-horizontal__item + .nav-horizontal__item {
        margin-left: 15px;
        font-size: 13px;
        line-height: 20px;
    }
    .web-items__image {
        width: 350px;
    }
    ul {
        margin: 12px 0 12px 16px;
    }
    .variants-panel {
        width: 100%;
        padding-right: 0;
    }
    .variants-slider {
        position: relative;
        left: 0;
        padding-bottom: 30px;
    }
    .variants-slider__image {
        margin-top: 0;
    }
    .variants__panel-container {
        padding-bottom: 35px;
    }
    .variants-item {
        padding: 20px;
    }
    .services-items__item {
        padding: 20px;
        align-items: center;
        width: calc(50% - 40px);
        flex: 0 0 calc(50% - 40px);
    }
    .oval {
        width: 400px;
        height: 400px;
        right: -200px;
    }
    .oval--top {
        top: -200px;
    }
    .oval--bottom {
        bottom: -200px;
    }
    .about-row__left {
        padding-right: 30px;
    }
    .about-row__right {
        padding-left: 30px;
    }
    .map__row {
        height: 400px;
    }
    .map__panel {
        max-width: 300px;
        padding: 30px;
    }
    .footer__container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .hero__picture {
        width: 380px;
    }
    .web-items__left .web-items__card::after {
        width: 100px;
    }
    .web-items__right .web-items__card::after {
        width: 100px;
    }
    .prices-slider__slide {
        padding: 30px 20px;
    }
    .prices-slider__title {
        margin-bottom: 10px;
    }
}
@media (max-width: 960px) {
    %container-padding {
        padding-left: 35px;
        padding-right: 35px;
    }
    .hero {
        padding-top: 50px;
    }
    .logo__img {
        height: 22px;
        margin-bottom: 6px;
    }
    .nav-horizontal {
        display: none;
    }
    .hero__title {
        width: 100%;
    }
    .hero__subtitle {
        width: 100%;
    }
    .header__container {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .hero {
        padding-bottom: 35px;
    }
    .hero {
        padding-bottom: 35px;
    }
    .history-items__icon {
        max-width: 180px;
    }
    %pt-150 {
        padding-top: 60px;
    }
    %pt-75 {
        padding-top: 30px;
    }
    %pb-75 {
        padding-bottom: 30px;
    }
    %pt-50 {
        padding-top: 25px;
    }
    %pb-50 {
        padding-bottom: 25px;
    }
    .section-title {
        margin-bottom: 35px;
    }
    .section-subtitle {
        margin-bottom: 20px;
        max-width: 100%;
    }
    .modal__container {
        max-width: 100%;
        min-width: 280px;
        padding: 30px;
    }
    .web-items__image {
        width: 280px;
    }
    .variants-items {
        margin: -10px;
    }
    .variants-item {
        margin: 10px;
    }
    .services-items {
        margin: -10px;
    }
    .services-items__item {
        width: calc(50% - 20px);
        flex: 0 0 calc(50% - 20px);
        margin: 10px;
    }
    .map__row {
        height: 350px;
    }
    .hero {
        height: 600px;
        justify-content: flex-start;
    }
    .hero__row {
        height: 100%;
    }
    .hero__picture {
        width: 300px;
        top: auto;
        bottom: 0;
        transform: translate(50%, 0);
        right: 50%;
    }
    .hero__title {
        text-align: center;
    }
    .hero__subtitle {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .web-items__card {
        padding: 10px;
    }
}
@media (max-width: 640px) {
    h1,
    .h1 {
        font-size: 31.9px;
    }
    h2,
    .h2 {
        font-size: 22.2px;
    }
    h3,
    .h3 {
        font-size: 16.6px;
    }
    h4,
    .h4 {
        font-size: 12.8px;
    }
    .hero {
        padding-top: 50px;
    }
    .hero__title {
        text-align: center;
        font-size: 28px;
    }
    .hero__subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
    .slider__bg {
        top: 40px;
    }
    .china-market__container {
        padding-top: 30px;
    }
    .china-market__text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
    }
    .benefits {
        max-width: 100%;
    }
    .benefits__item {
        flex-basis: 50%;
        margin-right: 0;
    }
    .benefits__item:nth-child(2n-1) {
        margin-right: 0;
    }
    .benefits__number {
        margin-bottom: 15px;
        font-size: 28px;
    }
    .benefits__text {
        margin-top: 10px;
        font-size: 14px;
        padding-right: 10px;
    }
    .benefits__item--margin-bottom {
        margin-bottom: 25px;
    }
    .oval {
        width: 200px;
        height: 200px;
        right: -100px;
    }
    .oval--bottom {
        bottom: -100px;
    }
    .form__column {
        width: 100%;
    }
    .swiper-control {
        padding-left: 0;
        width: 100%;
        flex: 0 0 100%;
        justify-content: center;
        margin-top: 20px;
    }
    .swiper-control__prev {
        margin-right: 20px;
    }
    .swiper-control__next {
        margin-left: 20px;
    }
    .swiper-control svg {
        width: 24px;
    }
    .variants-title__title {
        padding-right: 0;
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
    }
    .form__column:first-child {
        padding-bottom: 0;
    }
    .form__column:last-child {
        padding-top: 0;
    }
    .footer__form {
        padding: 20px 0;
    }
    .form__textarea {
        margin-bottom: 30px;
    }
    .footer__row {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }
    .map__logo-img {
        margin: auto;
    }
    .map__footer {
        text-align: center;
    }
    .map__text {
        text-align: center;
    }
    .map__bg {
        top: 40px;
    }
    .web-items__center {
        order: -1;
        margin-bottom: 20px;
    }
    .web-items {
        flex-wrap: wrap;
    }
    .web-items__image {
        width: 100%;
    }
    .web-items__left {
        width: 100%;
        flex-basis: 100%;
        margin: 0;
    }
    .web-items__card {
        text-align: center;
        margin-bottom: 1px;
    }
    .web-items__left .web-items__card::after {
        display: none;
    }
    .web-items__right {
        margin: 0;
        width: 100%;
        flex-basis: 100%;
    }
    .web-items__right .web-items__card::after {
        display: none;
    }
    .services-items__item {
        width: calc(100% - 20px);
        flex: 0 0 calc(100% - 20px);
    }
    .variants-item {
        width: calc(100% - 20px);
        flex: 0 1 calc(100% - 20px);
    }
    .main-form .policy {
        margin-top: 30px;
    }
    .about-row {
        flex-wrap: wrap;
    }
    .about-row__left {
        width: 50%;
        padding-right: 0;
        flex: 0 0 100%;
    }
    .about-row__right {
        width: 100%;
        padding-left: 0;
        flex: 0 0 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .about-line__icon {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .about-line__text {
        max-width: 100%;
        font-size: 13px;
        padding: 0 5px;
    }
    .about-line + .about-line {
        margin-top: 20px;
    }
    .about-line {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50%;
        padding-top: 20px;
    }
    .about-line + .about-line {
        margin-top: 0;
    }
    .map__map {
        display: none;
    }
    .map__row {
        height: 300px;
    }
    .footer__container {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .variants-slider__content {
        @extend %container;
        padding-bottom: 30px;
        display: block;
    }
    .variants__panel-container {
        display: none;
    }
    .variants-panel__title {
        justify-content: center;
    }
    .map__panel {
        max-width: 100%;
        flex-basis: 100%;
    }
    .web-items {
        display: none;
    }
    .prices-slider__title {
        font-weight: bold;
        font-size: 18px;
    }
}
