@font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Regular.woff') format('woff'),
    url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Bold.woff') format('woff'),
    url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url('../fonts/PT_Sans-Web-Regular.woff2') format('woff2'),
    url('../fonts/PT_Sans-Web-Regular.woff') format('woff'),
    url('../fonts/PT_Sans-Web-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'PT Sans';
    src: url('../fonts/PT_Sans-Web-Bold.woff2') format('woff2'),
    url('../fonts/PT_Sans-Web-Bold.woff') format('woff'),
    url('../fonts/PT_Sans-Web-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
